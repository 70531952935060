body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.5px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2,
h3 {
    font-family: 'Helvetica Neue';
    font-weight: 600;
    letter-spacing: 0.5px;
}

.btn,
.btn-dark,
.btn-check:focus + .btn-dark,
.btn-dark:focus,
.btn-check:focus + .btn,
.btn:focus {
    border: none;
    box-shadow: none;
}

.btn-dark:hover {
    background: #212529;
}

.bg-primary,
.btn-light {
    letter-spacing: 0.7px;
}
